import { StaticImage } from "gatsby-plugin-image"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Nav = () => {
  return (
    <div className="nav">
      <a href="/" class ="logo">
        {/* <StaticImage
          className="logo dark-logo"
          src="../images/logos/Logo (White on Transparent).png"
          alt="Dark mode logo"
        /> */}
      </a>
      {/* <a href="/">
        <StaticImage
          className="logo light-logo"
          src="../images/logos/Logo (Black on Transparent)-Recovered.png"
          alt="Light mode logo"
        />
      </a> */}
      <div className="theme-toggle">
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <div className="dark-button">
              <input
                type="checkbox"
                id="toggle"
                onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
                checked={theme === "dark"}
              />{" "}
              <label class="label" for="toggle">
                <FontAwesomeIcon icon={faMoon} className="fas fa-moon" />
                <FontAwesomeIcon icon={faSun} className="fas fa-sun" />
                <div className="ball"></div>
              </label>
            </div>
          )}
        </ThemeToggler>
      </div>
      <div className="nav-menu">
        <a href="/">Blog</a>
        <a href="https://notes.joshsandefer.dev">Notes</a>
        <a href="/">About</a>
        <a href="/">Portfolio</a>
      </div>
      <div className="footer-menu">
        <a href="/">About</a>
        <a href="/">Contact</a>
      </div>
      <div className="social-icons">
        <a href="https://www.twitter.com/thejoshsandefer" target="_blank">
          <StaticImage
            className="social-icon"
            src="../images/social-icons/twitter-icon-96px.png"
            alt="twitter icon"
          />
        </a>
        {/* <a href="twitter.com">
            <StaticImage
              className="social-icons"
              src="../images/github-icon-pink.png"
              alt="alt text"
            />
          </a>
          <a href="twitter.com">
            <StaticImage
              className="social-icons"
              src="../images/github-icon-pink.png"
              alt="alt text"
            />
          </a>
          <a href="twitter.com">
            <StaticImage
              className="social-icons"
              src="../images/github-icon-pink.png"
              alt="alt text"
            />
          </a> */}
      </div>
    </div>
  )
}

export default Nav
